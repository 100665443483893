import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { MAX_WORD_LENGTH, MAX_CHALLENGES } from '../../constants/settings'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
       Guess the author of the quote in {MAX_CHALLENGES} tries. Authors and Guesses can have any length to a maximum of {MAX_WORD_LENGTH} chars (including spaces). 
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
       After each guess, the color of the tiles will change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="O"
          status="correct"
        />
        <Cell value="S" />
        <Cell value="C" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value=" " />
        <Cell value="W" />
        <Cell value="I" />
        <Cell value="L" />
        <Cell value="D" />
        <Cell value="E" />

      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter O is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" />
        <Cell value="O" />
        <Cell value="N" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="present"
        />
        <Cell value="U" />
        <Cell value="C" />
        <Cell value="I" />
        <Cell value="U" />
        <Cell value="S" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter F is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" />
        <Cell value="A" />
        <Cell isRevealing={true} isCompleted={true} value="L" status="absent"/>
        <Cell value="A" />
        <Cell value="I" />
        <Cell value=" " />
        <Cell value="L" />
        <Cell value="A" />
        <Cell value="M" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is not in the word in any spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        You don't need to type special chars like "." "," or "-".
      </p>
    </BaseModal>
  )
}
