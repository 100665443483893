import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'
import { getWordOfDay } from '../../lib/words'



type Props = {
  rightLetters: string
  className: string
  guesses: string[]
}

const WordOfDayRevelead = ({ rightLetters, className, guesses }: Props) => {
  const wordOfDay = getWordOfDay().solution;
  const splitwordOfDay = unicodeSplit(wordOfDay);

  let isSolvedArray: Array<boolean> = [];
  for (let index = 0; index < wordOfDay.length; ++index) {
    isSolvedArray.push(false);
  }

  for (let index = 0; index < guesses.length; ++index) {
    for (let charindex = 0; charindex < guesses[index].length; ++charindex) {
      //In case the current guess is bigger than the word of day, just break the inner loop.
      if (charindex > wordOfDay.length) {
        break;        
      }
      if (wordOfDay.charAt(charindex) === guesses[index].charAt(charindex)) {
        isSolvedArray[charindex] = true;
      }
    }
  }
  return (
    <div className="flex justify-center mb-1">
      {splitwordOfDay.map((letter, i) => (
        isSolvedArray[i] ? 
          <Cell
            isRevealing={true}
            isCompleted={true}
            value={letter}
            status="correct"
          />
        : 
          <Cell value=" " />
      ))}
    </div>
  )
  
}

export const WordOfDay = ({ rightLetters, className, guesses }: Props) => {
  console.log(guesses);
  const wordOfDay = getWordOfDay().solution;
  const tipOfDay = getWordOfDay().tip;
  //const splitGuess = unicodeSplit(guess)
  //const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))
  const classes = `flex justify-center text-sm mb-1 dark:text-white ${className}`
  const quoteClass = `flex justify-center text-center mx-auto text-base ml-2.0 font-bold dark:text-white ${className}`
  //var max_word_length_text = '';
  //var max_guesses = '';

  //if (MAX_WORD_LENGTH) {
  //  max_word_length_text = `Guesses must have a maximum of ${MAX_WORD_LENGTH} letters.`;
  //}

  //if (MAX_CHALLENGES) {
  //  max_guesses = `${guesses.length - 1} of ${MAX_CHALLENGES} tries`;
  //}

  return (
    <>
    <div className={quoteClass}>"{tipOfDay}"</div>
    <div className={classes}>
      Author of the day has {wordOfDay.length} letters (including spaces).    
    </div>
    <WordOfDayRevelead rightLetters={rightLetters} className={className} guesses={guesses} />
    </>
  )
}
